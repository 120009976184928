import React, { useState, useEffect } from "react";
import "../../scss/components/packagecard.scss";
import rectangle from "../../assets/Rectangle.png";
// import Button from "react-bootstrap/Button";
import ToggleButton from "react-bootstrap/ToggleButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import IndvPackage from "./indv_package";
import { get_tv_package } from "../../data/api";
import tick from "../../assets/tick.svg";
import whitetick from "../../assets/whitetick.png";

import Widthcontainer from "./widthcontainer";

const PackageCard = ({ tvData, internetData }) => {
   // const [checked, setChecked] = useState(false);
   const [radioValue, setRadioValue] = useState("2");
   // const [tvPrice, setTvPrice] = useState();
   const radios = [
      { name: "Internet Packages", value: "2" },
      { name: "Television Packages", value: "1" },
   ];

   return (
      <div className="package_card">
         <p className="package_title">
            Live & On-<span style={{ color: "#0D8E42" }}>Demand.</span>
         </p>
         {radioValue === "1" ? (
            <p className="package_description">Get your best entertainment today with DishHome.</p>
         ) : (
            <p className="package_description">
               Get the best Internet deals with DishHome Internet.
            </p>
         )}

         <div className="toggle_button">
            {/* <div id="right-img">
               <img src={fancy}></img>
            </div> */}
            <div>
               {" "}
               <ButtonGroup>
                  {radios.map((radio, idx) => (
                     <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant={radioValue === radio.value ? "active" : "inactive"}
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                     >
                        {radio.name}
                     </ToggleButton>
                  ))}
               </ButtonGroup>
            </div>
         </div>
         {radioValue === "1" ? (
            <div className="package_card_wrapper" style={{ marginTop: "60px" }}>
               <div style={{ display: "flex" }} className="package_card_wrapper__wrapper">
                  {Object.entries(tvData).map(([key, value]) => {
                     let price = value.prices[0];
                     // console.log("price", price);
                     return (
                        <div className={key === "1" ? "HD_package active" : "HD_package  "}>
                           <div className="rectangle_image">
                              <img
                                 style={{ marginTop: "30px" }}
                                 src={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                                 alt="package"
                                 height={60}
                                 width={60}
                              ></img>
                           </div>
                           <p className="package_name">{value.title}</p>
                           <p className="package_des">
                              {/* Power up your business with out{" "}
                                        <br></br> invoice{" "} */}
                              {value.description}
                           </p>
                           <p className="package_cost">
                              <sup style={{ fontSize: "12px" }}>Rs.</sup>{" "}
                              <span className="cost">
                                 {price === undefined ? 0 : price.price.toLocaleString("hi-IN")}*
                              </span>
                              <sub>/month</sub>
                           </p>
                           <div className="channels_list">
                              <li>
                                 {" "}
                                 {key === "1" ? (
                                    <img
                                       style={{ width: "16.67px", height: "16.67px" }}
                                       src={whitetick}
                                       alt="tick"
                                    />
                                 ) : (
                                    <img src={tick} alt="tick" />
                                 )}
                                 Installation Charge: Rs.
                                 {price === undefined
                                    ? 0
                                    : price.installation_charge.toLocaleString("hi-IN")}
                              </li>
                              <li>
                                 {" "}
                                 {key === "1" ? (
                                    <img
                                       style={{ width: "16.67px", height: "16.67px" }}
                                       src={whitetick}
                                       alt="tick"
                                    />
                                 ) : (
                                    <img src={tick} alt="tick" />
                                 )}
                                 Set Top Box: Rs.{" "}
                                 {price === undefined
                                    ? 0
                                    : price.settopbox_price.toLocaleString("hi-IN")}
                              </li>
                              <li>
                                 {" "}
                                 {key === "1" ? (
                                    <img
                                       style={{ width: "16.67px", height: "16.67px" }}
                                       src={whitetick}
                                       alt="tick"
                                    />
                                 ) : (
                                    <img src={tick} alt="tick" />
                                 )}
                                 No. of Tv: {price === undefined ? 0 : price.multiple}
                              </li>
                              <li>
                                 {" "}
                                 {key === "1" ? (
                                    <img
                                       style={{ width: "16.67px", height: "16.67px" }}
                                       src={whitetick}
                                       alt="tick"
                                    />
                                 ) : (
                                    <img src={tick} alt="tick" />
                                 )}
                                 Additional Charges: {""}
                                 Rs.
                                 {price === undefined
                                    ? 0
                                    : price.additional.toLocaleString("hi-IN")}
                              </li>
                           </div>
                           <div className="package_link">
                              <a
                                 className={key === "1" ? "link_active" : "link "}
                                 // href={`/tv/` + value._id + "?type=1 Month"}
                                 href={"/tv/" + value._id}
                              >
                                 {" "}
                                 View Package
                              </a>
                           </div>
                        </div>
                     );
                  })}
               </div>
            </div>
         ) : (
            <Widthcontainer>
               <div className="package_card_wrapper" style={{ marginTop: "60px" }}>
                  <div style={{ display: "flex" }} className="package_card_wrapper__wrapper">
                     {Object.entries(internetData).map(([key, value]) => {
                        let price = value?.dashain_offer ? value.prices[3] : value.prices[0];
                        return (
                           <div className={key === "1" ? "HD_package active" : "HD_package "}>
                              <div className="rectangle_image">
                                 <img
                                    style={{ marginTop: "30px" }}
                                    src={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                                    alt="package"
                                    height={60}
                                    width={60}
                                 ></img>
                              </div>
                              <p className="package_name">{value.title}</p>
                              <p className="package_des">
                                 {value.description?.substring(0, 100)}
                                 {
                                    //show ... if description is more than 60 characters
                                    value.description?.length > 100 ? "..." : ""
                                 }
                              </p>
                              <p className="package_cost">
                                 <sup id={"sup-style"} style={{ fontSize: "12px" }}>
                                    Rs.
                                 </sup>{" "}
                                 <span className="cost">
                                    {price?.internet_price.toLocaleString("hi-IN")}*
                                 </span>
                                 <sub id="sup-style">
                                    {value?.dashain_offer ? " /12 months" : " /month"}
                                 </sub>
                              </p>
                              <div className="channels_list">
                                 <li>
                                    {" "}
                                    {key === "1" ? (
                                       <img
                                          src={whitetick}
                                          style={{ width: "16.67px", height: "16.67px" }}
                                          alt="tick"
                                       />
                                    ) : (
                                       <img src={tick} alt="tick" />
                                    )}
                                    Speed: {value.speed}
                                 </li>
                                 {/* <li>
                                    {" "}
                                    {key === "1" ? (
                                       <img src={whitetick} alt="tick" />
                                    ) : (
                                       <img src={tick} alt="tick" />
                                    )}
                                    ONT Type: {value?.ont_type}
                                 </li> */}
                                 {/* <li>
                                    {" "}
                                    {key === "1" ? (
                                       <img src={whitetick} alt="tick" />
                                    ) : (
                                       <img src={tick} alt="tick" />
                                    )}
                                    Installation Charge: Rs.{" "}
                                    {price?.installation_charge.toLocaleString("hi-IN")}
                                 </li> */}
                                 <li>
                                    {" "}
                                    {key === "1" ? (
                                       <img src={whitetick} alt="tick" />
                                    ) : (
                                       <img src={tick} alt="tick" />
                                    )}
                                    Router Rental Charge: Rs.{" "}
                                    {price?.router.toLocaleString("hi-IN")}
                                 </li>
                                 <li>
                                    {" "}
                                    {key === "1" ? (
                                       <img src={whitetick} alt="tick" />
                                    ) : (
                                       <img src={tick} alt="tick" />
                                    )}
                                    Drop Wire Charge: Rs. {price?.drop_wire.toLocaleString("hi-IN")}
                                 </li>

                                 {/* {price?.no_of_itv > 0 && (
                                    <li>
                                       {" "}
                                       {key === "1" ? (
                                          <img src={whitetick} alt="tick" />
                                       ) : (
                                          <img src={tick} alt="tick" />
                                       )}
                                       No. of TV: {price?.no_of_itv}
                                    </li>
                                 )} */}
                                 {/* {price?.no_of_itv > 0 && (
                                    <li>
                                       {" "}
                                       {key === "1" ? (
                                          <img src={whitetick} alt="tick" />
                                       ) : (
                                          <img src={tick} alt="tick" />
                                       )}
                                       TV Charge: {price?.itv_price.toLocaleString("hi-IN")}
                                    </li>
                                 )}
                                 {price?.no_of_itv > 0 && (
                                    <li>
                                       {" "}
                                       {key === "1" ? (
                                          <img src={whitetick} alt="tick" />
                                       ) : (
                                          <img src={tick} alt="tick" />
                                       )}
                                       TV Setup Box Charge:{" "}
                                       {price?.itv_stb_charge.toLocaleString("hi-IN")}
                                    </li>
                                 )} */}
                              </div>
                              <div className="package_link">
                                 <a
                                    className={key === "1" ? "link_active" : "link "}
                                    href={
                                       value?.dashain_offer
                                          ? `/internet/${value._id}?type=12 Months`
                                          : `/internet/${value._id}`
                                    }
                                 >
                                    {" "}
                                    View Package
                                 </a>
                              </div>
                           </div>
                        );
                     })}
                  </div>
               </div>
            </Widthcontainer>
         )}
      </div>
   );
};
export default PackageCard;
