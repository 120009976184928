import React, { useRef, useState, useEffect } from "react";
import Layout from "../include/layout";
import Widthcontainer from "../customcomponents/widthcontainer";
import DhInfoBanner from "../customcomponents/dhinfobanner";
import "../../scss/pages/sme.scss";
import triangle from "../../assets/internet/triangle.png";
import { Button, Col, NavItem } from "react-bootstrap";
import { Navigate, useLocation, useNavigate } from "react-router";
import { BiChevronRight } from "react-icons/bi";

import {
   internet_requests,
   sme_data,
   get_sme_banner,
   get_internet_package,
   get_internet_components,
} from "../../data/api";
import MessageModal from "../customcomponents/messageModal";
import { Form, Modal } from "react-bootstrap";
import SeoTags from "../../utils/seoTags";
import Loading from "../customcomponents/loading";
import EnterpriseBanner from "../banner/enterprisebanner";
import { Formik } from "formik";
import * as yup from "yup";
import Captcha from "../include/captcha";

export default function Sme() {
   const formSection = useRef(null);
   const [smeData, setSmeData] = useState("");
   const [loading, setLoading] = useState(true);
   const [empty, setEmpty] = useState(true);

   const [internetData, setInternetData] = useState("");
   const [banner, setBanner] = useState("");
   const [seoData, setSeoData] = useState("");
   const [messageShow, setMessageShow] = useState(false);
   const [smePackage, setSmepackage] = useState("");

   useEffect(() => {
      async function fetchData() {
         const response = await get_sme_banner();
         setBanner(response?.data?.banners);
         setSeoData(response?.data?.seo);
      }
      fetchData();
   }, [loading]);

   //SME PACKAGE
   useEffect(() => {
      async function fetchSmeData() {
         const response = await get_internet_components();

         setInternetData(response.data);
      }
      fetchSmeData();
   }, [empty]);
   useEffect(() => {
      setSmepackage(internetData?.smeInternetPackage);
      if (internetData?.seo === undefined) {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   });

   useEffect(() => {
      if (
         smeData === "" ||
         smeData === undefined ||
         banner === undefined ||
         seoData === undefined
      ) {
         setLoading(true);
      } else {
         setLoading(false);
      }
   });

   useEffect(() => {
      //get interprise data
      const getEnterpriseData = async () => {
         const response = await sme_data();
         setSmeData(response?.data?.data);
      };
      getEnterpriseData();
   }, []);
   const scrollDown = (ref) => {
      window.scrollTo(0, ref.current.offsetTop - 150);
   };
   const [show, setShow] = useState(false);

   return loading || empty ? (
      <Loading />
   ) : (
      <Layout>
         {Object.entries(seoData).map(([key, value]) => {
            if (value?.page === "sme") {
               return SeoTags(
                  value?.title,
                  value?.title,
                  "",
                  value?.keyword,
                  "",
                  `${process.env.REACT_APP_PUBLIC_URL}internet/${value?.page}`,
               );
            } else {
               return null;
            }
         })}
         {Object.entries(banner).map(([key, value]) => {
            if (value.page_location === "sme_top") {
               return <EnterpriseBanner data={value} setShow={setShow} muted={false} />;
            } else return null;
         })}

         <SmePackage internetPackage={smePackage} />

         <div style={{ height: "120px" }}></div>
         <h1 className="enterpriseHeading">
            Connecting to <span>Success.</span>
         </h1>
         {Object.entries(smeData?.grow_your_business).map(([key, value]) => {
            return (
               <Widthcontainer className={"enterprise_section_container"}>
                  <EnterpriseSection2
                     rowreverse={key % 2 === 0 ? false : true}
                     title={value.title}
                     desc={value.description}
                     img={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                     setShow={setShow}
                  />
               </Widthcontainer>
            );
         })}

         <DhInfoBanner page={"SME"} />
         <Widthcontainer className={"sme_section_container margin_top_bottom"}>
            <Modal
               show={show}
               onHide={() => {
                  setShow(false);
               }}
               dialogClassName="sme_section_container"
               centered
            >
               <Modal.Body
                  style={{
                     display: "flex",
                     justifyContent: "center",
                     borderRadius: "12px",
                     paddingTop: "20px",
                     paddingBottom: "20px",
                  }}
               >
                  <SmeForm
                     show={show}
                     section={formSection}
                     setModaldisplay={setMessageShow}
                     setShow={setShow}
                  />
               </Modal.Body>
            </Modal>
            <MessageModal
               show={messageShow}
               setShow={setMessageShow}
               heading="Thank You"
               message="Our Representative will get back to you soon"
               isNavigate={false}
               buttonText="OK"
               type={"success"}
            />
         </Widthcontainer>
      </Layout>
   );
}

export const SmePackage = ({ internetPackage }) => {
   let navigate = useNavigate();
   return (
      <div>
         <div className="internetpackage-wrapper flex-column">
            <div
               className="container"
               style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
            >
               {Object.entries(internetPackage).map(([key, value]) => {
                  return (
                     <div style={{ marginBottom: "17px" }}>
                        <div className="internet" id={key == 1 ? "middle-div" : ""}>
                           {key === "1" && <img id="triangle-img" src={triangle} alt="wifi" />}

                           <div className="wifi">
                              <img
                                 src={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                                 alt="wifi"
                              />
                           </div>

                           <p className="mbps" id={key == 1 ? "middle-mbps" : ""}>
                              {value.title}
                           </p>
                           <p className="plans" id={key == 1 ? "middle-mbps" : ""}>
                              {value.description}
                           </p>
                           <div id={key == 1 ? "middle-mbps" : ""}>
                              {Object.entries(value.prices).map(([key, item]) => {
                                 return (
                                    <div>
                                       {item.type === "1 Month" ? (
                                          <div>
                                             <p
                                                className="month"
                                                id={key == 1 ? "middle-mbps" : ""}
                                             >
                                                Rs.{" "}
                                                <span
                                                   className="unique"
                                                   id={key == 1 ? "middle-mbps" : ""}
                                                >
                                                   {item.internet_price?.toLocaleString("hi-IN")}*
                                                </span>
                                                /month
                                             </p>
                                          </div>
                                       ) : (
                                          ""
                                       )}
                                    </div>
                                 );
                              })}
                           </div>

                           <div className="wrapper-button">
                              {key === "1" ? (
                                 <Button
                                    variant="secondary"
                                    onClick={() => {
                                       navigate(`/internet/${value._id}`);
                                    }}
                                    className="choose_middle"
                                 >
                                    <span id="btn-text"> Choose</span>
                                 </Button>
                              ) : (
                                 <Button
                                    onClick={() => {
                                       navigate(`/internet/${value._id}`);
                                    }}
                                    className="choose"
                                    variant="secondary"
                                 >
                                    Choose
                                 </Button>
                              )}
                           </div>
                        </div>
                     </div>
                  );
               })}
            </div>
            <div className="container">
               <p className="internet-para">
                  Choose the internet packages that best suit your business needs.
               </p>
               {/* <p className="view_more_plan" onClick={() => navigate("/internet/plans")}>
                  View More Plans <BiChevronRight />
               </p> */}
            </div>
         </div>
      </div>
   );
};

export const EnterpriseSection2 = ({ rowreverse, title, img, desc, setShow }) => {
   return (
      <div
         className={
            rowreverse
               ? "enterprise_section fixed-width row-reverse padding-top-bottom"
               : "enterprise_section fixed-width padding-top-bottom"
         }
      >
         <div className="enterprise_img">
            <img src={img} alt="top" />
         </div>
         <div className="enterprise_text_section">
            <h1 className="heading">{title}</h1>
            <p className="description">{desc}</p>
            <div className="buttonGroup">
               <button className="enterprise_button" onClick={() => setShow(true)}>
                  Inquiry
               </button>
            </div>
         </div>
      </div>
   );
};
export const SmeForm = ({ section, setModaldisplay, show, setShow }) => {
   const [btnState, setBtnState] = useState(false);
   const reRef = useRef();

   const handleSubmit = async (values) => {
      setBtnState(true);
      // e.preventDefault();
      let phone = values?.phone;
      if (values?.phone?.length > 10) {
         if (values?.phone?.charAt(0) === "0") {
            phone = phone.substring(1);
         } else if (values?.phone?.slice(0, 4) === "+977") {
            phone = phone?.slice(4, 18);
         }
      }
      const captchaToken = await reRef.current?.executeAsync();
      // console.log("Token", captchaToken);
      const response = await internet_requests(
         "SME",
         values?.companyName,
         values?.contactName,
         values?.email,
         phone?.toString(),
         values?.requirement,
         values?.location,
         values?.remarks,
         captchaToken,
      );
      if (response?.status === 201) {
         setModaldisplay(true);
         setShow(false);
         setBtnState(false);
      } else {
         alert(response?.response?.data?.message);
         setBtnState(false);
      }
   };

   const schema = yup.object().shape({
      companyName: yup
         .string()
         .required("*Required")
         .min(2, "Company Name must be at least 2 characters")
         .max(100),
      contactName: yup
         .string()
         .required("*Required")
         .min(2, "Contact Name must be at least 2 characters")
         .max(50),
      email: yup.string().email("Email is invalid").required("Email is required").min(6).max(60),
      phone: yup
         .string()
         .min(10, "Please Enter Valid Phone Number")
         .max(14, "Please Enter Valid Phone Number")
         .required("Phone number is required"),
      requirement: yup
         .string()
         .required("Requirement is required")
         .min(5, "Requirement must me atleast 5 characters ")
         .max(350),
      location: yup
         .string()
         .required("Location is required")
         .min(5, "Location must me atleast 5 characters long")
         .max(350),
      remarks: yup
         .string()
         .required("Remarks is required")
         .min(5, "Remarks must me atleast 5 characters ")
         .max(350),
   });
   const handleClose = () => {
      setShow(false);
   };
   return (
      <div className="formContainer fixed-width" ref={section}>
         <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            enableReinitialize
            initialValues={{
               companyName: "",
               contactName: "",
               email: "",
               phone: "",
               requirement: "",
               location: "",
               remarks: "",
            }}
         >
            {({ handleSubmit, handleChange, values, setFieldValue, touched, errors }) => (
               <Form
                  enableReinitialize
                  className="formWrapper"
                  noValidate
                  onSubmit={handleSubmit}
                  autoComplete="off"
               >
                  {/* {console.log("show_btn", show)} */}
                  <div className="sme_text pb-3">
                     <h1>
                        Contact Us For <span> SME </span>Inquiry
                     </h1>
                  </div>
                  <div className="inputGroup">
                     <div className="inputContainer">
                        <label htmlFor="companyName">Company Name</label>
                        <input
                           type="text"
                           name="companyName"
                           className={errors?.companyName ? "Name_Input error" : "Name_Input"}
                           value={values?.companyName}
                           minLength="2"
                           maxLength={"90"}
                           onChange={(e) => {
                              if (e?.nativeEvent?.data) {
                                 if (e?.nativeEvent?.data?.toString()?.match("[A-Za-z \b]")) {
                                    handleChange(e);
                                 }
                                 if (e?.nativeEvent?.data === null) {
                                    handleChange(e);
                                 }
                              } else {
                                 handleChange(e);
                              }
                           }}
                           placeholder=""
                        />
                        {errors?.companyName ? (
                           <p className="error_message" style={{ color: "#ed1c24" }}>
                              {errors?.companyName}
                           </p>
                        ) : null}
                     </div>
                     <div className="inputContainer">
                        <label htmlFor="contactName">Contact Name</label>
                        <input
                           type="text"
                           name="contactName"
                           minLength="2"
                           maxLength={"50"}
                           className={errors?.contactName ? "Name_Input error" : "Name_Input"}
                           value={values?.contactName}
                           onChange={(e) => {
                              if (e?.nativeEvent?.data) {
                                 if (e?.nativeEvent?.data?.toString()?.match("[A-Za-z \b]")) {
                                    handleChange(e);
                                 }
                                 if (e?.nativeEvent?.data === null) {
                                    handleChange(e);
                                 }
                              } else {
                                 handleChange(e);
                              }
                           }}
                           placeholder=""
                        />
                        {errors?.contactName ? (
                           <p className="error_message" style={{ color: "#ed1c24" }}>
                              {errors?.contactName}
                           </p>
                        ) : null}
                     </div>
                  </div>
                  <div className="inputContainer">
                     <label htmlFor="email">Email</label>
                     <input
                        type="email"
                        name="email"
                        minLength="2"
                        maxLength={"60"}
                        className={errors?.email ? "Email_Input error" : "Email_Input"}
                        value={values?.email}
                        onChange={handleChange}
                        placeholder=""
                     />
                     {errors?.email ? (
                        <p className="error_message" style={{ color: "#ed1c24" }}>
                           {errors?.email}
                        </p>
                     ) : null}
                  </div>
                  <div className="inputContainer">
                     <label htmlFor="phone">Phone</label>
                     <input
                        type="tel"
                        name="phone"
                        maxLength={14}
                        className={errors?.phone ? "Phone_Input error" : "Phone_Input"}
                        value={values?.phone}
                        onChange={(e) => {
                           if (e?.nativeEvent?.data) {
                              if (e?.nativeEvent?.data?.toString()?.match("[0-9+\b]")) {
                                 handleChange(e);
                              }
                              if (e?.nativeEvent?.data === null) {
                                 handleChange(e);
                              }
                           } else {
                              handleChange(e);
                           }
                        }}
                        placeholder=""
                     />
                     {errors?.phone ? (
                        <p className="error_message" style={{ color: "#ed1c24" }}>
                           {errors?.phone}
                        </p>
                     ) : null}
                  </div>

                  <div className="inputContainer">
                     <label htmlFor="location">Location</label>
                     <input
                        type="text"
                        name="location"
                        className={errors?.location ? "Location_Input error" : "Location_Input"}
                        value={values?.location}
                        onChange={(e) => {
                           if (e?.nativeEvent?.data) {
                              if (e?.nativeEvent?.data?.match("[0-9A-za-z- ,]")) {
                                 handleChange(e);
                              }
                              if (e?.nativeEvent?.data === null) {
                                 handleChange(e);
                              }
                           } else {
                              handleChange(e);
                           }
                        }}
                        placeholder=""
                        minLength="5"
                        maxLength={"350"}
                     />
                     {errors?.location ? (
                        <p className="error_message" style={{ color: "#ed1c24" }}>
                           {errors?.location}
                        </p>
                     ) : null}
                  </div>
                  <div className="inputContainer mb-2">
                     <label htmlFor="requirement">Requirement</label>
                     <textarea
                        type="textarea"
                        name="requirement"
                        className={
                           errors?.requirement ? "Requirement_Input error" : "Requirement_Input"
                        }
                        value={values?.requirement}
                        onChange={(e) => {
                           if (e?.nativeEvent?.data) {
                              if (e?.nativeEvent?.data?.toString()?.match("[0-9A-Za-z, \b]")) {
                                 handleChange(e);
                              }
                              if (e?.nativeEvent?.data === null) {
                                 handleChange(e);
                              }
                           } else {
                              handleChange(e);
                           }
                        }}
                        placeholder=""
                        minLength="5"
                        maxLength={"350"}
                     />
                     {errors?.requirement ? (
                        <p className="error_message" style={{ color: "#ed1c24" }}>
                           {errors?.requirement}
                        </p>
                     ) : null}
                  </div>
                  <div className="inputContainer">
                     <label htmlFor="remark">Remark</label>
                     <textarea
                        type="textarea"
                        name="remarks"
                        className={errors?.remarks ? "Remark_Input error" : "Remark_Input"}
                        value={values?.remarks}
                        onChange={(e) => {
                           if (e?.nativeEvent?.data) {
                              if (e?.nativeEvent?.data?.toString()?.match("[0-9A-Za-z, \b]")) {
                                 handleChange(e);
                              }
                              if (e?.nativeEvent?.data === null) {
                                 handleChange(e);
                              }
                           } else {
                              handleChange(e);
                           }
                        }}
                        placeholder=""
                        minLength="5"
                        maxLength={"350"}
                     />
                     {errors?.remarks ? (
                        <p className="error_message" style={{ color: "#ed1c24" }}>
                           {errors?.remarks}
                        </p>
                     ) : null}
                  </div>
                  <div className="button_wrapper_modal">
                     <Button
                        variant="secondary"
                        type="button"
                        className={" cancel "}
                        onClick={() => setShow(false)}
                     >
                        Cancel
                     </Button>
                     <button
                        className={btnState ? "sme_button loading" : "sme_button "}
                        type="submit"
                        disabled={btnState}
                     >
                        {btnState ? "Loading ..." : "Submit"}
                     </button>
                  </div>
                  <Captcha reRef={reRef} />
               </Form>
            )}
         </Formik>
      </div>
   );
};
