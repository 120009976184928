import React, { useState, useEffect } from "react";
import "../../scss/components/appbanner.scss";
import googleplay from "../../assets/googleplay.webp";
import apple from "../../assets/appstore.webp";
import logowhite from "../../assets/logowhite.webp";
import { get_footer_banner } from "../../data/api";
import { useMediaQuery } from "react-responsive";
import bottom1 from "../../assets/bottomellipse.webp";
import bottom2 from "../../assets/bottomellipse2.webp";
import bottom3 from "../../assets/bottomellipse3.webp";
import bottom4 from "../../assets/bottomellipse4.webp";

export default function AppBanner(props) {
   const [isEmpty, setisEmpty] = useState(true);
   const [footerData, setfooterData] = useState("");
   const [ottlink, setOttlink] = useState("");
   const [normallink, setNormallink] = useState("");
   const [banner, setBanner] = useState("");

   const isMobile = useMediaQuery({ query: "(max-width: 860px)" });
   useEffect(() => {
      async function getData() {
         const response = await get_footer_banner();
         setfooterData(response?.data);
      }
      getData();
   }, [isEmpty]);

   useEffect(() => {
      if (footerData === "" || footerData === undefined) {
         setisEmpty(true);
      } else {
         setisEmpty(false);
         setOttlink(footerData?.ott_link);
         setNormallink(footerData?.other_link);
         setBanner(footerData?.banners);
      }
   }, [footerData]);

   let ottBanner;
   let normalBanner;
   return isEmpty ? null : props.data === "dashain" ? null : props.data === "ott" ? (
      <div className="appbanner ott">
         {Object.entries(banner).map(([key, value]) => {
            if (value.page_location === "footer_ott") {
               ottBanner = value;
            }
            return null;
         })}
         <div
            style={
               isMobile
                  ? {
                       display: "flex",
                       justifyContent: "center",
                       alignItems: "center",
                       flexWrap: "wrap",
                    }
                  : {
                       display: "flex",
                       justifyContent: "space-between",
                       alignItems: "center",
                       flexWrap: "wrap",
                    }
            }
            className="fixed-width"
         >
            <div className="appbanner-text ps-2 mt-2">
               <img src={logowhite} style={{ marginBottom: "20px" }} alt="" />
               <p className="TV">{ottBanner?.caption}</p>
               <p className="TV_des">{ottBanner?.sub_caption}</p>
               <div className="download_box">
                  <a className="download_button">
                     <img
                        src={googleplay}
                        style={{ cursor: "pointer" }}
                        height="45px"
                        width="130px"
                        alt=""
                        onClick={() => {
                           window.open(ottlink?.google_play_go, "_blank");
                        }}
                     />
                  </a>
                  <a className="download_button">
                     <img
                        src={apple}
                        height="45px"
                        width="130px"
                        style={{ marginLeft: "10px" }}
                        alt=""
                        onClick={() => {
                           window.open(ottlink?.app_store_go, "_blank");
                        }}
                     />
                  </a>
               </div>
            </div>
            <div className="appbanner-image">
               <img
                  src={
                     ottBanner?.media_type === "image"
                        ? `${process.env.REACT_APP_FILE_URL}/${ottBanner?.media}`
                        : `${process.env.REACT_APP_FILE_URL}/${ottBanner?.thumbnail}`
                  }
                  height="400px"
                  style={{ marginTop: "45px", paddingRight: "55px" }}
                  alt=""
               ></img>
            </div>
         </div>
      </div>
   ) : (
      <div className="appbanner">
         {Object.entries(banner).map(([key, value]) => {
            if (value.page_location === "footer_main") {
               normalBanner = value;
            }
            return null;
         })}
         <img id="bottom1" src={bottom1} />
         <img id="bottom2" src={bottom2} />
         <img id="bottom3" src={bottom3} />
         <img id="bottom4" src={bottom4} />

         <div
            style={
               isMobile
                  ? {
                       display: "flex",
                       justifyContent: "center",
                       alignItems: "center",
                       flexWrap: "wrap",
                    }
                  : {
                       display: "flex",
                       justifyContent: "space-between",
                       alignItems: "center",
                       flexWrap: "wrap",
                    }
            }
            className="fixed-width"
         >
            <div className="appbanner-text ps-2 pt-3" style={isMobile ? {} : { flexBasis: "45%" }}>
               <img src={logowhite} style={{ marginBottom: "20px" }} alt="" />
               <p className="TV">{normalBanner?.caption}</p>
               <p className="TV_des">{normalBanner?.sub_caption}</p>
               <div className="download_box">
                  <a className="download_button">
                     <img
                        src={googleplay}
                        height="45px"
                        width="130px"
                        alt=""
                        onClick={() => {
                           window.open(normallink?.google_play, "_blank");
                        }}
                     />
                  </a>
                  <a className="download_button">
                     <img
                        src={apple}
                        height="45px"
                        width="130px"
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                        alt=""
                        onClick={() => {
                           window.open(normallink?.app_store, "_blank");
                        }}
                     />
                  </a>
               </div>
            </div>
            <div
               className="appbanner-image"
               style={isMobile ? { flexBasis: "75%" } : { flexBasis: "45%" }}
            >
               <img
                  src={
                     normalBanner?.media_type === "image"
                        ? `${process.env.REACT_APP_FILE_URL}/${normalBanner?.media}`
                        : `${process.env.REACT_APP_FILE_URL}/${normalBanner?.thumbnail}`
                  }
                  style={{ marginTop: "45px", objectFit: "contain", width: "100%" }}
                  alt=""
               ></img>
            </div>
         </div>
      </div>
   );
}
